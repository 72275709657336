// 异步加载css
export function loadCss(url) {
  let linkEl = document.createElement("link");
  linkEl.setAttribute("rel", "stylesheet");
  linkEl.setAttribute("href",url);
  var heads = document.getElementsByTagName("head");
  if (heads.length)
    heads[0].appendChild(linkEl);
  else
    document.documentElement.appendChild(linkEl);
}

// 异步加载js
export function loadScripts(array, callback) {
  var loader = function (src, handler) {
    var script = document.createElement("script");
    script.src = src;
    script.onload = script.onreadystatechange = function () {
      script.onreadystatechange = script.onload = null;
      handler();
      // console.log(script.async) // 注1
    }
    var head = document.getElementsByTagName("head")[0];
    (head || document.body).appendChild(script);
  };
  (function run() {
    if (array.length != 0) {
      loader(array.shift(), run);
    } else {
      callback && callback();
    }
  })();
}