<template>
  <main>
    <div class="header">
      <h1 class="header-title" style="cursor: pointer;">{{ currTarget.name }}</h1>
      <el-dropdown class="dropdown" @command="changeCurrTarget">
        <span class="el-dropdown-link">
          切換平臺<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item, index) in targetList" :key="index" :command="index">{{ item.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div class="switch-target"> 
        <a
          v-for="(item, index) in targetList"
          :key="index"
          :class="{actived: currIndex === index}"
          href="#"
          :title="`切換到${item.name}`"
          @click="changeCurrTarget(index)"
        >
          <el-image class="img" :src="item.points[0].pic" fit="cover"></el-image>
        </a>  
      </div>
      <div class="header-info header-info-l">健康狀況：健康</div>
      <div class="header-info header-info-r">數據時間：<span>{{ nowDate }}</span></div>
    </div>
    <div class="content">
      <div class="col col-l">
        <div class="xpanel-wrapper xpanel-wrapper-60">
          <div class="xpanel xpanel-l-t">
            <div class="title">今日數據</div>
            <div class="chart-div chart-done">
              <table class="data-t">
                <tr>
                  <th>
                    <img src="/images/factors/temp.png" />
                    <p>温度(℃)</p>
                  </th>
                  <td>
                    <p><span id="aveTemp">0</span></p>
                    <p>平均</p>
                  </td>
                  <td>
                    <p><span id="minTemp">0</span></p>
                    <p>最小</p>
                  </td>
                  <td>
                    <p><span id="maxTemp">0</span></p>
                    <p>最大</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <img src="/images/factors/hum.png" />
                    <p>濕度(%)</p>
                  </th>
                  <td>
                    <p><span id="aveHum">0</span></p>
                    <p>平均</p>
                  </td>
                  <td>
                    <p><span id="minHum">0</span></p>
                    <p>最小</p>
                  </td>
                  <td>
                    <p><span id="maxHum">0</span></p>
                    <p>最大</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <img src="/images/factors/wind.png" />
                    <p>風力(m/s)</p>
                  </th>
                  <td>
                    <p><span id="aveWind">0</span></p>
                    <p>平均</p>
                  </td>
                  <td>
                    <p><span id="minWind">0</span></p>
                    <p>最小</p>
                  </td>
                  <td>
                    <p><span id="maxWind">0</span></p>
                    <p>最大</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <img src="/images/factors/strain.png" />
                    <p>應變(με)</p>
                  </th>
                  <td>
                    <p><span id="aveStrain">0</span></p>
                    <p>平均</p>
                  </td>
                  <td>
                    <p><span id="minStrain">0</span></p>
                    <p>最小</p>
                  </td>
                  <td>
                    <p><span id="maxStrain">0</span></p>
                    <p>最大</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <img src="/images/factors/dist.png" />
                    <p>伸缩缝(mm)</p>
                  </th>
                  <td>
                    <p><span id="aveDist">0</span></p>
                    <p>平均</p>
                  </td>
                  <td>
                    <p><span id="minDist">0</span></p>
                    <p>最小</p>
                  </td>
                  <td>
                    <p><span id="maxDist">0</span></p>
                    <p>最大</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <img src="/images/factors/bend.png" />
                    <p>撓度(mm)</p>
                  </th>
                  <td>
                    <p><span id="aveBend">0</span></p>
                    <p>平均</p>
                  </td>
                  <td>
                    <p><span id="minBend">0</span></p>
                    <p>最小</p>
                  </td>
                  <td>
                    <p><span id="maxBend">0</span></p>
                    <p>最大</p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <img src="/images/factors/mems.png" />
                    <p>傾斜(°)</p>
                  </th>
                  <td>
                    <p><span id="aveMems">0</span></p>
                    <p>平均</p>
                  </td>
                  <td>
                    <p><span id="minMems">0</span></p>
                    <p>最小</p>
                  </td>
                  <td>
                    <p><span id="maxMems">0</span></p>
                    <p>最大</p>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="xpanel-wrapper xpanel-wrapper-40">
          <div class="xpanel xpanel-l-b">
            <div class="title">今日預警</div>
            <el-table :data="warns">
              <el-table-column prop="PointName" min-width="120px" label="測點名稱">
              </el-table-column>
              <el-table-column prop="WarnLevel" label="預警級別">
                <template v-slot="scope">
                  <span v-if="scope.row.WarnLevel === 'Yellow'" style="color: yellow"
                    >黃色預警</span
                  >
                  <span v-else-if="scope.row.WarnLevel === 'Orange'" style="color: orange"
                    >橙色預警</span
                  >
                  <span v-else style="color: red">紅色預警</span>
                </template>
              </el-table-column>
              <el-table-column prop="WarnValue" label="預警限值"> </el-table-column>
              <el-table-column prop="Value" label="測量值"> </el-table-column>
              <el-table-column prop="Time" :formatter="dateFormatter" label="日期" width="180">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="col col-c-2" id="Map3dContainer">
        <div id="ScenesPicker" class="showPicker">
          <ul class="dock">
            <li v-for="(point, index) in currTarget.points" :key="index">
              <a
                href="#"
                :title="point.title"
                @click="gis.flyto(...point.entity)"
              >
                <img :src="point.pic" :alt="point.title" />
              </a>
            </li>
            <!-- <li>
              <a
                href="#"
                title="蓮花大橋"
                @click="gis.flyto(113.55382334327803,22.135772588136152,556.9652449743264,5.452555822603758,-0.5658094284784738,6.281137616074284)"
              >
                <img src="/images/locations/1.jpg" alt="蓮花大橋" />
              </a>
            </li>
            <li>
              <a
                href="#"
                title="嘉樂庇總督大橋"
                @click="gis.flyto(113.53666593899179,22.152577296268714,1085.2494725967645,0.40463009052085575,-0.4557947290721489,0.0010271052079904308)"
              >
                <img src="/images/locations/2.jpg" alt="嘉樂庇總督大橋" />
              </a>
            </li> -->
          </ul>
        </div>
        <div id="cesiumContainer"></div>
        <el-tree
          v-if="treeData && treeData.length"
          class="bimTree"
          :data="treeData"
          :props="defaultProps"
          accordion
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
    </div>
    <div class="footer"></div>
  </main>
</template>
<script>
import '@/styles/summary.css'
// import $ from '@/lib/jquery/dist/jquery.min.js';
// window.$ = $;
const $ = window.$;
import { loadScripts,loadCss } from '@/utils/util.js';
import Gis from '@/js/view_gis';
import CountUp from '@/js/countUp.min';
var token = '' //'$('<input name="__RequestVerificationToken" type="hidden" value="CfDJ8H6GK7smxjdFsVPkG469biwpQV-enV5ta37m5UIs1Wii8gUAxhzErIuMeRDlhO_No7l3O0JqzGLH-pcZMgMJNajrGLlhwV9A7RUxkIrwGdoG8NKBZmXoOkwZtl3Fy2JSAY4sQf4U651e5vQg5eX_WDI" />').val()';
//var gisAddr = '/gis_tiles/tileset.json'
var gisAddr = 'https://gvi-hk.oss-cn-hongkong.aliyuncs.com/rest/services/3DTilesService/datasources/aomen20220527/tilesets/0/tileset.json'
var editable = false

Date.prototype.Format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
      )
    }
  }
  return fmt
}
//数字变化特效
function rollNum(elId, startVal, endVal, decimalNum) {
  let n = decimalNum || 0
  let countUp = new CountUp(elId, startVal, endVal, n, 2.5, {
    useEasing: true,
    useGrouping: true,
    separator: ',',
    decimal: '.'
  })
  if (!countUp.error) {
    countUp.start()
  } else {
    console.error(countUp.error)
  }
}
let dateformat = 'yyyy-MM-dd';

export default {
  data() {
    return {
      // warns: {"Values":{"温度":{"Name":"溫度","Decimal":1,"Ave":19.910000000000004,"Min":19.3,"Max":21.2},"湿度":{"Name":"濕度","Decimal":2,"Ave":46.03999999999999,"Min":43.32,"Max":48.16},"位移":{"Name":"位移","Decimal":2,"Ave":150.84400000000005,"Min":135.19,"Max":162.48},"挠度":{"Name":"撓度","Decimal":2,"Ave":4.72,"Min":-0.59,"Max":11.03},"风力":{"Name":"風速","Decimal":2,"Ave":2.4010000000000002,"Min":0.82,"Max":4.18},"应变":{"Name":"應變","Decimal":2,"Ave":null,"Min":null,"Max":null},"倾斜":{"Name":"傾斜","Decimal":2,"Ave":0.183,"Min":-0.11,"Max":0.42}},"Warnings":[]}
      warns: [],
      targetList: [
        {
          name: '嘉樂庇總督大橋監測平臺',
          isInit: false,
          defaultEntity: [113.5430161554679, 22.153622302992115, 822.5546971914954, 0.0737429697579568, -0.34594798506450064, 0.00018343525345176914],
          dataUrl: '/json/jlb.json',
          items: [],
          points: [
            {
              title: 'B墩視角',
              entity: [113.54097512195679, 22.185605477068425, 24.451601486680154, 0.20716544591465524, -0.047744982743452846, 0.0004825145601365932],
              pic: '/images/locations/jlb/5.jpg'
            },
            {
              title: '跨中視角',
              entity: [113.54170499221446, 22.17921148564294, 96.20868955056741, 0.37156911392965686, -0.3110450009219097, 0.0008939702659844784],
              pic: '/images/locations/jlb/4.jpg'
            },
            {
              title: 'C墩視角',
              entity: [113.54355009020713, 22.17532646531317, 20.718471465449724, 0.24654015034933607, -0.46420556790532475, 0.0006399574588247248],
              pic: '/images/locations/jlb/3.jpg'
            },
            {
              title: '本橋視角',
              entity: [113.54300554344002, 22.172677083450637, 86.14759179932656, 0.052688278039323855, -0.27876795180296, 0.00012836975357988223],
              pic: '/images/locations/jlb/2.jpg'
            },
            {
              title: '總體視角',
              entity: [113.5430161554679, 22.153622302992115, 822.5546971914954, 0.0737429697579568, -0.34594798506450064, 0.00018343525345176914],
              pic: '/images/locations/jlb/1.jpg'
            }
          ]
        },
        {
          name: '蓮花大橋監測平臺',
          isInit: false,
          defaultEntity: [113.55382334327803,22.135772588136152,556.9652449743264,5.452555822603758,-0.5658094284784738,6.281137616074284],
          dataUrl: '/json/lhdq.json',
          items: [],
          points: [
            {
              title: '蓮花大橋',
              entity: [113.55382334327803,22.135772588136152,556.9652449743264,5.452555822603758,-0.5658094284784738,6.281137616074284],
              pic: '/images/locations/1.jpg'
            },


            {
              title: '俯視圖',
              entity: [113.54903690667014,22.14125369032309,1375.5788050072974,4.836735739993975,-1.4213318421713623,6.267568604110441],
              pic: '/images/locations/2.jpg'
            },
            {
              title: '',
              entity: [113.58017533807498,22.13305657663291,3068.7627552444296,5.355422548778646,-0.6475061867914951,6.280836401561258],
              pic: '/images/locations/5.jpg'
            }
          ]
        }
      ],
      currIndex: 0,
      nowDate: new Date().Format(dateformat),
      values: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      gis: null
    }
  },
  computed: {
    currTarget() {
      let _currTarget = this.targetList[this.currIndex];
      document.title = _currTarget.name
      return _currTarget
    },
    // 左侧树形控件数据
    treeData() {
      var bimData = []
      for (var i = 0; i < this.currTarget.items.length; i++) {
        var item = this.currTarget.items[i]
        let targetList = bimData;
        if (item.Position) { // 如果不用Position分组表示只渲染一级
          var parentNode = bimData.find(pItem => pItem.label === item.Position);
          if (!parentNode) {
            parentNode = {
              label: item.Position,
              children: []
            }
            bimData.push(parentNode)
          }
          targetList = parentNode.children
        }
        if (item.Type === 1) {
          // camera
          targetList.push({
            id: item.Id,
            label: item.Name,
            data: item
          })
        } else {
          targetList.push({
            id: item.Id,
            label: item.Name,
            type: item.SubTypeId,
            data: item
          })
        }
      }
      return bimData;
    },
  },
  watch: {
    currTarget: {
      immediate: true,
      handler() {
        if (!this.gis) {
          return;
        }
        this.$nextTick(() => {
          this.gis.flyto(...this.currTarget.defaultEntity)
        })
      }
    }
  },
  mounted() {
    let _this = this;
    loadCss('/lib/GovCesium/Widgets/widgets.css')
    loadScripts(['/lib/GovCesium/Cesium.js'], () => {
      console.log(window.Cesium)
      _this.gis = new Gis()
      _this.gis.attach('cesiumContainer', gisAddr, editable, function (id, n, x, y, z) {
        console.log("set location", n, x, y, z)
        /*$.ajax({
          url: '?handler=SetGisLocation&id=' + id + '&x=' + x + '&y=' + y + '&z=' + z + '&name=' + n,
          type: 'get',
          dataType: 'json',
          async: true,
          contentType: 'application/json; charset=UTF-8',
          data: JSON.stringify(_this.times),
          beforeSend: function (xhr) {
            xhr.setRequestHeader('XSRF-TOKEN', token)
          },
          success: function () {},
          error: function (request, status, error) {
            if (error == '') {
              _this.$alert(request.responseText)
            } else {
              _this.$alert(error)
            }
          }
        })*/
      })
      _this.gis.viewer.camera.moveEnd.addEventListener(function () {
        console.log('moveEnd')
      })
      _this.refresh()
      _this.timer = setInterval(_this.refresh, 300000)
      _this.changeCurrTarget(this.currIndex)
    })
  },
  methods: {
    async changeCurrTarget(command) {
      this.currIndex = command
      this.gis.viewer.selectedEntity = null;
      if (!this.currTarget.isInit) {
        this.getTargetData();
      }
    },
    getTargetData() {
      let _this = this;
      $.ajax({
        url: this.currTarget.dataUrl,
        type: 'get',
        async: true,
        contentType: 'application/json; charset=UTF-8',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('XSRF-TOKEN', token)
        },
        success: function (items) {
          _this.currTarget.items = items;
          _this.currTarget.isInit = true;
          // console.log(items)     
          // 设置热点
          for (var j = 0; j < items.length; j++) {
            var item = items[j]
            if (item.Model && item.Model.length > 0) {
              item.Entity = _this.gis.addModel(
                item.Id,
                item.ShortName,
                item.RefId,
                item.Type,
                item.Model,
                item.GisX,
                item.GisY,
                item.GisZ,
                item.Head,
                item.Pitch,
                item.Roll
              )
            } else {
              item.Entity = _this.gis.addPoint(
                item.Id,
                item.ShortName,
                item.RefId,
                item.Type,
                item.GisX,
                item.GisY,
                item.GisZ
              )
            }
          }
        }
      })
    },
    showNode(item) {
      console.log(item)
      this.gis.viewer.flyTo(item.Entity)
      this.gis.viewer.selectedEntity = item.Entity
    },
    handleNodeClick(e) {
      if (window.Cesium.defined(e.id)) {
        this.showNode(e.data);
      }
    },
    update(allValues, name, label) {
      var values = allValues[name]
      if (values == null) return
      var oldValue = this.values[name]
      var oldAve = 0,
        oldMin = 0,
        oldMax = 0
      if (oldValue != null) {
        if (oldValue.Ave != null) oldAve = oldValue.Ave
        if (oldValue.Min != null) oldMin = oldValue.Min
        if (oldValue.Max != null) oldMax = oldValue.Max
      }
      if (values.Ave != null) rollNum('ave' + label, oldAve, values.Ave, values.Decimal)
      if (values.Min != null) rollNum('min' + label, oldMin, values.Min, values.Decimal)
      if (values.Max != null) rollNum('max' + label, oldMax, values.Max, values.Decimal)
    },
    // 刷新左侧数据
    refresh() {
      var time = new Date()
      this.nowDate = time.Format(dateformat)

      var self = this
      $.ajax({
        url: '/Views/Summary?handler=RealData',
        type: 'get',
        async: true,
        contentType: 'application/json; charset=UTF-8',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('XSRF-TOKEN', token)
        },
        success: function (data) {
          self.update(data.Values, '温度', 'Temp')
          self.update(data.Values, '湿度', 'Hum')
          self.update(data.Values, '风力', 'Wind')
          self.update(data.Values, '应变', 'Strain')
          self.update(data.Values, '位移', 'Dist')
          self.update(data.Values, '挠度', 'Bend')
          self.update(data.Values, '倾斜', 'Mems')
          self.warns = data.Warnings
          // console.log('-----', self.warns, data.Warnings, data.Warnings === self.warns)
        }
      })
    },
    ColorFormat(color) {
      var newColor = ''
      switch (color) {
        case 'Green':
          newColor = '无预警'
          break
        case 'Yellow':
          newColor = '黄色预警'
          break
        case 'Orange':
          newColor = '橙色预警'
          break
        case 'Red':
          newColor = '红色预警'
          break
      }
      this.fomtColor = newColor
    },
    levelFormatter(row) {
      switch (row.MaxWarnLevel) {
        case 'Yellow':
          return '黄色预警'
        case 'Orange':
          return '橙色预警'
        case 'Red':
          return '红色预警'
      }
      return ''
    },
    dateFormatter(row, col) {
      return new Date(row[col.property]).Format('yyyy-MM-dd hh:mm:ss')
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  overflow: visible;
}
.dropdown {
  position: absolute;
  left: 66%;
  // margin-left: 270px;
  top: 10px;
  color: #73aae5;
  cursor: pointer;
}
.switch-target {
  position: absolute;
  left: 74%;
  top: 15px;
  z-index: 100;
  a {
    display: inline-block;
    width: 80px;
    height: 60px;
    border: 3px solid #202124;
    transition: width 0.2s ease-in;
    &:last-child {
      margin-left: -3px;
    }
    &.actived {
      width: 120px;
      // border-color: #eee;
      position: relative;
      z-index: 2;
    }
    .img {
      width: 100%;
      height: 100%;
    }
  }
}
#paint1 {
  margin-top: 30px;
}
.bimTree {
  position: absolute;
  top: 20px;
  background: transparent;
}

/deep/ {
  .cesium-infoBox-iframe {
    min-height: 600px;
  }
  .el-tree-node__content {
    color: white;
  }
  .el-tree-node:focus > .el-tree-node__content {
    background-color: #3c8dbc;
  }
  .el-tree-node__content:hover {
    background-color: #357ca5;
  }
}
</style>
