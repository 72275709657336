//"use strict";
export default function gis() {
	this.viewer = null;
	this.tileset = null;
	this.models = [];
	this.isLeftDown = false;
	this.dragged = null;
	var self = this;
	this.callback = null;
	this.lastPoint = null;
	this.attach = function (container, url, editable, callback) {
		console.log(`cesium version: ${Cesium.VERSION}`);
		self.callback = callback;
		const ALLOW_3D_TILES_INSPECTOR = false;
		const ALLOW_NODE_PROPS = true;

		Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(113.46389761912052,
			22.051530988575948,
			113.65070689329953,
			22.238484322570155);
		this.viewer = new Cesium.Viewer(container, {
			geocoder: false,
			homeButton: false,
			sceneModePicker: false,
			baseLayerPicker: true,
			navigationHelpButton: false,
			animation: false, //左下角的动画控件的显示
			shouldAnimate: false, //控制模型动画
			timeline: false,
			fullscreenButton: false,
			//imageryProviderViewModels: [],
			//terrainProviderViewModels:[]
			orderIndependentTranslucency: false,
			contextOptions: {
				webgl: {
					alpha: true,
				}
			},
		});

		$('.cesium-viewer-toolbar').hide();
		$('.cesium-infoBox-iframe')[0].setAttribute('sandbox', 'allow-scripts allow-same-origin allow-popups allow-forms');
		this.viewer.scene.globe.depthTestAgainstTerrain = true;
		this.viewer.scene.skyBox.show = false;
		this.viewer.scene.backgroundColor = new Cesium.Color(0.0, 0.0, 0.0, 0.0);

		//hide copyright info
		this.viewer._cesiumWidget._creditContainer.style.display = "none";

		this.viewer.scene.moon = null;
		this.viewer.scene.sun = null;


		this.viewer.scene.globe.depthTestAgainstTerrain = true;
		var scene = this.viewer.scene;
		if (!scene.pickPositionSupported) {
			console.log('This browser does not support pickPosition.');
		}


		//3D Tiles Inspector
		if (ALLOW_3D_TILES_INSPECTOR) {
			this.viewer.extend(Cesium.viewerCesium3DTilesInspectorMixin);
			var inspectorViewModel = viewer.cesium3DTilesInspector.viewModel;
		}

		//Load Model:
		this.tileset = (function (viewer, zoomTo, url) {
			"use strict";
			var tileset = viewer.scene.primitives.add(new Cesium.Cesium3DTileset({
				url: url,
				luminanceAtZenith: 0.2,
				lightColor: new Cesium.Cartesian3(0.3, 0.3, 0.3)
			}));

			tileset.readyPromise.then(function (tileset) {
				viewer.camera.viewBoundingSphere(tileset.boundingSphere,
					new Cesium.HeadingPitchRange(0, -0.5, 0));
				viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
			}).otherwise(function (error) {
				throw (error);
			})

			if (ALLOW_NODE_PROPS) {
				// 过滤只能指定id可以高亮和选择
				//bim.attachTileset(items, viewer, tileset, itemIds);
			}

			if (zoomTo) {
				viewer.zoomTo(tileset, new Cesium.HeadingPitchRange(0, -0.5, 0));
			}

			return tileset;
		})(this.viewer, true, url);

		//get geographic coordinate from mouse click
		(function (viewer) {
			viewer.scene.canvas.addEventListener('click',
				function (e) {
					var ellipsoid = viewer.scene.globe.ellipsoid;
					var cartesian = viewer.camera.pickEllipsoid(new Cesium.Cartesian2(e.clientX, e.clientY),
						ellipsoid);
					if (cartesian) {
						var cartographic = ellipsoid.cartesianToCartographic(cartesian);
						var plon = Cesium.Math.toDegrees(cartographic.longitude);
						var plat = Cesium.Math.toDegrees(cartographic.latitude);
						console.log(`Click point coordinate: (${plon.toFixed(10)}, ${plat.toFixed(10)})`);
					}
					var pos = self.getPos();
					console.log(`current pos (${pos.lng},${pos.lat},${pos.alt},${pos.heading},${pos.pitch},${pos.roll})`);
				});
		})(this.viewer);

		if (editable) {
			var handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);
			handler.setInputAction(function (e) {
				var pick = self.viewer.scene.pick(e.position);
				self.isLeftDown = true;
				if (pick && pick.id && pick.id.url) {
					self.dragged = pick;
					self.viewer.scene.screenSpaceCameraController.enableRotate = false;
				}
			}, Cesium.ScreenSpaceEventType.LEFT_DOWN);
			handler.setInputAction(function (e) {
				if (self.dragged && self.dragged.id && self.callback && self.lastPoint) {
					self.callback(self.dragged.id.dbId, self.dragged.id.name, Cesium.Math.toDegrees(self.lastPoint.longitude), Cesium.Math.toDegrees(self.lastPoint.latitude), self.lastPoint.height);
				}
				self.lastPoint = null;
				self.isLeftDown = false;
				self.dragged = null;
				self.viewer.scene.screenSpaceCameraController.enableRotate = true;
			}, Cesium.ScreenSpaceEventType.LEFT_UP);
			handler.setInputAction(function (e) {
				if (self.isLeftDown == true && self.dragged !== null && self.dragged.id) {
					console.log(e.endPosition);
					let cartesian = self.viewer.scene.pickPosition(e.endPosition);
					if (cartesian) {
						self.dragged.id.position = cartesian;
						var cartographic = Cesium.Cartographic.fromCartesian(cartesian);
						self.lastPoint = cartographic;
						console.log(`${self.dragged.id.name}: ${Cesium.Math.toDegrees(cartographic.longitude)}, ${Cesium.Math.toDegrees(cartographic.latitude)}, ${cartographic.height}`);

					}
				}
			}, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
		}

		this.viewer._selectedEntityChanged.addEventListener(function (e) {
			if (e != null) {
				$('.cesium-infoBox-iframe')[0].src = e.url;
			} else {
				$('.cesium-infoBox-iframe')[0].src = "";
			}
		});
	}
	this.onModelAdded = function (dbId, model, refId, typeId) {
		var url;
		if (typeId == 1) {
			url = "/Views/Video?id=" + refId;
		} else {
			url = "/Views/SensorData?id=" + refId;
		}
		model.url = url;
		model.refId = refId;
		model.typeId = typeId;
		model.dbId = dbId;
		model.description = '<iframe sandbox="allow-scripts allow-same-origin allow-popups allow-forms" frameborder="0" src="' + url + '" style="width:100%;height:580px" ></iframe>';
		this.models.push(model);
	}
	this.addPoint = function (dbId, name, refId, typeId, x, y, z) {
		var entity = this.viewer.entities.add({
			name: name,
			position: Cesium.Cartesian3.fromDegrees(x, y, z),
			point: {
				pixelSize: 5,
				color: Cesium.Color.RED,
				outlineColor: Cesium.Color.WHITE,
				outlineWidth: 2
			},
			label: {
				text: name,
				font: '14pt monospace',
				style: Cesium.LabelStyle.FILL_AND_OUTLINE,
				outlineWidth: 2,
				verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
				pixelOffset: new Cesium.Cartesian2(0, -9),
				eyeOffset: new Cesium.Cartesian3(0, 0, -10)
			},
		});
		this.onModelAdded(dbId, entity, refId, typeId);
		return entity;
	}
	this.addModel = function (dbId, name, refId, typeId, url, x, y, z, heading, pitch, roll, scale) {
		x = Cesium.defaultValue(x, 0.0);
		y = Cesium.defaultValue(y, 0.0);
		z = Cesium.defaultValue(z, 0.0);
		heading = Cesium.defaultValue(heading, 0.0);
		pitch = Cesium.defaultValue(pitch, 0.0);
		roll = Cesium.defaultValue(roll, 0.0);
		scale = Cesium.defaultValue(scale, 0.2);
		var pos = Cesium.Cartesian3.fromDegrees(x, y, z);
		//var orientation = Cesium.Transforms.headingPitchRollQuaternion(pos, heading, pitch, roll);
		entity = this.viewer.entities.add({
			model: {
				uri: url,
				scale: scale
			},
			name: name,
			position: pos,
			//orientation:orientation,			
			label: {
				text: name,
				font: '14pt monospace',
				style: Cesium.LabelStyle.FILL_AND_OUTLINE,
				outlineWidth: 2,
				verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
				pixelOffset: new Cesium.Cartesian2(0, -9)
			},
		});
		this.onModelAdded(entity, dbId, refId, typeId);
		return entity;
	}
	this.Cartesian3_to_WGS84 = function (point) {
		var cartesian33 = new Cesium.Cartesian3(point.x, point.y, point.z);
		var cartographic = Cesium.Cartographic.fromCartesian(cartesian33);
		var lat = Cesium.Math.toDegrees(cartographic.latitude);
		var lng = Cesium.Math.toDegrees(cartographic.longitude);
		var alt = cartographic.height;
		return {
			lng: lng,
			lat: lat,
			alt: alt
		};
	}
	this.getPos = function () {
		var pos = this.viewer.camera.position;
		pos = self.Cartesian3_to_WGS84(pos);
		return {
			lng: pos.lng,
			lat: pos.lat,
			alt: pos.alt,
			heading: this.viewer.camera.heading,
			pitch: this.viewer.camera.pitch,
			roll: this.viewer.camera.roll
		};
	}
	this.flyto = function (lng, lat, alt, head, pitch, roll) {
		lng = Cesium.defaultValue(lng, 0.0);
		lat = Cesium.defaultValue(lat, 0.0);
		alt = Cesium.defaultValue(alt, 0.0);
		head = Cesium.defaultValue(head, 0.0);
		pitch = Cesium.defaultValue(pitch, 0.0);
		roll = Cesium.defaultValue(roll, 0.0);

		this.viewer.camera.flyTo({
			destination: Cesium.Cartesian3.fromDegrees(lng, lat, alt),
			orientation: {
				heading: head,
				pitch: pitch,
				roll: roll
			}
		});
	}
};